import type { Value } from 'expr-eval'
import {
  resolveRelativePath,
  resolveVariable,
  useManagerFormValue,
  extractVariables,
  useEvaluation,
  mergeWithDescriptors,
} from '@manager'
import type { MaybeRef, WatchStopHandle } from 'vue'

export const useFormula = () => {
  const { resolveExpression, parse, evaluate } = useEvaluation()
  const dayjs = useDayjs()
  const { formValue } = useManagerFormValue()

  const $dateDiff = (a: Value, b: Value) => {
    if (!a || !b) return null
    const startDate = a ? dayjs(a as Date | string).add(1, 'days') : a
    const endDate = dayjs(b as Date | string)
    return endDate.formatDiff(startDate)
  }
  const $monthDiff = (a: Value, b: Value) => {
    if (!a || !b) return null
    const startDate = dayjs(a as Date | string).startOf('day')
    const endDate = dayjs(b as Date | string).endOf('day')
    return endDate.diff(startDate, 'month', true)
  }

  const stopWatchers: WatchStopHandle[] = []

  const createFormula = (
    formula: MaybeRef<string | null | undefined>,
    parentPath: string | undefined,
  ) => {
    const resolvedFormula = computed(() =>
      resolveExpression(unref(formula), parentPath),
    )
    const expression = computed(() => parse(unref(resolvedFormula)))
    const evaluated = ref<any>(null)

    const stop = watch(
      [expression, formValue],
      ([expression, formValue]) => {
        if (!expression || !formValue) {
          evaluated.value = null
          return
        }

        evaluated.value = evaluate({
          expression,
          default: null,
          values: mergeWithDescriptors(formValue, {
            $dateDiff,
            $monthDiff,
          }),
        })
      },
      { immediate: true, deep: true },
    )

    stopWatchers.push(stop)

    tryOnScopeDispose(() => {
      for (const stop of stopWatchers) {
        stop()
      }
    })

    return { formula: resolvedFormula, evaluated }
  }

  return { createFormula }
}
